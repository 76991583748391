.topWrapper {
  height: fit-content;
  padding: 10px 0;
  border-bottom: 2px solid #12CB77;
  background: url(top_bg.jpg) no-repeat;
  background-size: cover;
}

.topWrapper::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.logo_data::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.logo,
.dataWrapper {
  float: left;
}

.logo {
  margin-right: 20px;
}

.logo .logo_img {
  background: url(onenz.png) no-repeat 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
  margin: 20px 10px 20px 10px;
  float: left;
}

.logo .logo_title {
  float: left;
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-top: 30px;
  margin-right: 20px;
}

.noAccess {
  color: red;
  position: absolute;
  top: 12vh;
  left: 10px;
  width: 20%;
  font-weight: bold;
}

.dataWrapper::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.customerInfo {
  padding-top: 10px;
  font-size: 15px;
}

.customerInfo .column1 {
  float: left;
  width: 310px;
}

.customerInfo .column2 {
  float: left;
  width: 310px;
}

.customerInfo .column3 {
  float: left;
  width: 280px;
}

.customerInfo .infoTitle {
  font-style: normal;
  display: block;
  float: left;
}

.customerInfo .column1 .infoTitle {
  width: 90px;
}

.customerInfo .column2 .infoTitle {
  width: 100px;
}

.customerInfo .column3 .infoTitle {
  width: 90px;
}

.customerInfo .colon {
  font-style: normal;
  width: 35px;
  display: block;
  float: left;
}

.customerInfo .infoValue {
  font-style: normal;
  width: 170px;
  display: block;
  word-break: break-word;
  float: left;
}

.customerInfo .redLight {
  width: 28px;
  height: 12px;
  border-radius: 6px;
  display: block;
  float: left;
  margin-top: 5px;
  background-color: red;
}

.customerInfo .column3 .infoValue {
  width: 150px;
}

.customerInfo,
.infoWrapper {
  color: white;
}

.infoWrapper::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.customerInfo .notice {
  position: absolute;
  top: 13vh;
  color: #12CB77;
}

.progressBarWrapper {
  position: absolute;
  top: 12vh;
  left: 10px;
  width: 245px;
}

.pbMessage {
  color: white;
}

.pbActive {
  display: block;
}

.pbHidden {
  display: none;
}

.movement {
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 255, 0, 0.7) 25%, white 50%, rgba(0, 255, 0, 0.7) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(right, rgba(255, 255, 255, 0) 0%, rgba(0, 255, 0, 0.7) 25%, white 50%, rgba(0, 255, 0, 0.7) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(right, rgba(255, 255, 255, 0) 0%, rgba(0, 255, 0, 0.7) 25%, white 50%, rgba(0, 255, 0, 0.7) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 255, 0, 0.7) 25%, white 50%, rgba(0, 255, 0, 0.7) 75%, rgba(255, 255, 255, 0) 100%);
  width: 100px;
  height: 10px;
  position: relative;
  -webkit-animation: gradient 2s linear infinite;
  animation: gradient 2s linear infinite;
}

.progressBar {
  width: 100%;
  overflow: hidden;
  height: 10px;
  background-color: #1b8957;
  border: 1px solid #eeeeee;
}

@-webkit-keyframes gradient {
  0% {
    left: -100px;
  }

  100% {
    left: 200px;
  }
}

@keyframes gradient {
  0% {
    left: -100px;
  }

  100% {
    left: 200px;
  }
}

.stateWrapper {
  width: 84%;
  margin-left: 265px;
  margin-top: 10px;
}

.stateWrapper::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.vulnerable {
  animation: flash 4s infinite;
  float: left;
  margin-right: 25vw;
  color: red;
}

.vulnerable .light {
  width: 28px;
  height: 12px;
  border-radius: 6px;
  display: inline-block;
  margin-top: 5px;
  background-color: red;
}

.updateTime {
  float: left;
}

.updateTimeText {
  display: inline-block;
  margin-left: 20px;
  color: white;
}

.stateWrapper .tooltips-icon {
  color: white;
}

.faq {
  float: right;
  margin-right: 10px;
  margin-top: -4px;
}

.faqDropdown {
  height: 32px;
  padding: 0 10px;
  font-family: sans-serif;
  font-size: 16px;
  color: black;
  background-color: white;
  border: 1px solid grey;
  border-radius: 20px;
  box-shadow: inset 1px 3px 4px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  background-image: url('./green_down.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-size: 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.auth-header {
  float: right;
  margin-right: 10px;
}

.wifi-tool {
  width: 60%;
  margin: 10px 0 0 265px;
  border: 1px solid;
  padding: 10px;
  background-color: #f0f2f5;
  border-radius: 10px;
  position: relative;
}

.wifi-tool .header {
  display: inline-block;
}

.wifi-tool .wifi-headline {
  margin-left: 14px;
}

.wifi-tool .wifi-message-header {
  margin-left: 20px;
  font-weight: bold;
}

.wifi-tool .wifi-message-body {
  margin-top: 10px;
  margin-left: 10px;
  color: #979bb1;
  font-size: 14px;
}

.wifi-tool .faq {
  position: absolute;
  bottom: 8px;
  right: 4px;
}

.wifi-tool .faq .faqDropdown {
  width: 180px;
}

@media screen and (min-width: 1200px) and (max-width: 1760px) {
  .progressBarWrapper {
    margin-top: 80px;
  }

  .stateWrapper {
    margin-top: 10px;
    width: 96%;
    text-align: right;
    margin-left: 0;
  }

  .vulnerable,
  .updateTime,
  .faq,
  .auth-header {
    float: none;
  }

  .faq {
    margin-top: 8px;
  }

  .vulnerable {
    margin-right: 0;
  }
}

@media screen and (min-width: 940px) and (max-width: 1199px) {
  .progressBarWrapper {
    margin-top: 80px;
  }

  .customerInfo {
    width: 650px;
  }

  .customerInfo .column3 {
    width: 100%;
  }

  .customerInfo .column3 .infoValue {
    width: 80%;
  }

  .updateTime,
  .auth-header,
  .faq {
    float: none;
    margin-top: 2px;
  }

  .wifi-tool .wifi-message-body {
    width: 60%;
  }
}

@media screen and (min-width: 570px) and (max-width: 939px) {

  .progressBarWrapper,
  .noAccess {
    top: 80px;
    left: 50%;
    width: 40%;
  }

  .customerInfo {
    margin-left: 20px;
    width: 600px;
  }

  .customerInfo .column3 {
    width: 100%;
  }

  .customerInfo .column3 .infoValue {
    width: 80%;
  }

  .stateWrapper {
    margin-left: 20px;
    margin-top: 10px;
    width: 100%;
  }

  .vulnerable,
  .updateTime,
  .faq,
  .auth-header {
    float: none;
  }

  .faq {
    margin-top: 8px;
  }

  .vulnerable {
    margin-right: 0;
  }

  .wifi-tool {
    width: 90%;
    margin-left: 20px;
  }

  .wifi-tool .wifi-message-body {
    width: 60%;
  }
}

@media screen and (min-width: 500px) and (max-width: 569px) {
  .progressBarWrapper {
    top: 80px;
    left: 55%;
    width: 40%;
  }

  .noAccess {
    top: 60px;
    left: 55%;
    width: 40%;
  }

  .customerInfo {
    margin-left: 20px;
  }

  .vulnerable,
  .updateTime,
  .faq,
  .auth-header {
    float: none;
    margin-top: 4px;
  }

  .faq {
    margin-top: 8px;
  }

  .vulnerable {
    margin-right: 0;
  }

  .stateWrapper {
    margin-left: 20px;
    margin-top: 10px;
    width: 100%;
  }

  .wifi-tool {
    width: 90%;
    margin-left: 20px;
  }

  .wifi-tool .wifi-message-body {
    width: 50%;
  }
}