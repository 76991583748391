.ontStatusOvertime {
  font-size: 18px;
  margin: 10px 20px 20px;
}

.chart-container {
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  height: 500px;
  margin-top: 10px;
}

.ONTChevron {
  cursor: pointer;
  margin-left: 10px;
}

.expand {
  display: flex;
}

.collapse {
  display: none;
}

.y-axis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  margin-right: 10px;
  position: absolute;
  left: 0;
}

.y-axis-label {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  padding: 1px 0px;
}

.chart {
  display: flex;
  position: absolute;
  left: 40px;
  border: 1px solid #7f7f7f;
  border-radius: 8px;
  overflow: hidden;
}

.x-axis {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  width: 396px;
  position: absolute;
  top: 436px;
  left: 40px;
}

.x-axis-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 18px;
  height: 20px;
}

.x-axis-date {
  position: absolute;
  left: 160px;
  font-size: 16px;
  top: 460px;
}

.blocks {
  display: grid;
  grid-template-columns: repeat(22, 18px);
  grid-template-rows: repeat(24, 18px);
}

.block {
  width: 18px;
  height: 18px;
}

.date-container {
  position: absolute;
  width: fit-content;
  height: 20px;
  font-size: 12px;
  border: 1px solid #7f7f7f;
  padding: 0px 2px;
  z-index: 2;
  background-color: white;
}

.trafficUsage {
  position: absolute;
  left: 450px;
  font-size: 16px;
  top: 100px;
  width: 300px;
  text-align: center;
}

.trafficUsage .title {
  color: #7f7f7f;
}

.trafficUsage .usage i {
  font-style: normal;
}

.trafficUsage .usage .upload {
  margin-right: 26px;
}

.trafficUsage .usage .download {
  margin-left: 20px;
}

.trafficUsage .loading i {
  font-style: normal;
  padding-left: 14px;
}


.colorsInfo {
  position: absolute;
  font-size: 14px;
  left: 470px;
  top: 320px;
}

.ontStatusOvertime .colorsInfo .colorBlock {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 4px;
  border-radius: 0;
  margin-left: 0;
}

.colorsInfo .yellow {
  background-color: #ffd600;
}

.colorsInfo .orange {
  background-color: #ff8200;
}

.colorsInfo .red {
  background-color: #d31f3f;
}

.colorsInfo .black {
  background-color: black;
}

.colorsInfo .grey {
  background-color: grey;
}

@media screen and (min-width: 950px) and (max-width: 1390px) {
  .trafficUsage {
    left: 0;
    top: 500px;
    width: 270px;
  }

  .colorsInfo {
    left: 280px;
    top: 500px;
  }
}

@media screen and (min-width: 500px) and (max-width: 760px) {
  .trafficUsage {
    left: 0;
    top: 500px;
    width: 270px;
  }

  .colorsInfo {
    left: 280px;
    top: 500px;
  }
}