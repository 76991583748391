.noauthed {
    background: url(./bg.png) no-repeat center center;
    height: 70vh;
    padding-top: 30vh;
}

.noauth-notice {
    position: absolute;
    width: 100%;
    margin-top: 30px;
}

.header-center {
    width: 50%;
    height: fit-content;
    position: relative;
    margin: 0 auto;
}

.header-title {
    text-align: center;
    color: #fff;
    font-size: 32px;
    text-shadow: 0 0 0.3rem #00d8ff;
    line-height: 32px;
}

.welcome {
    display: inline-block;
    margin-right: 8px;
    color: #12CB77;
}

.welcomeName {
    font-weight: bold;
    font-style: normal;
}

.signOutButton {
    background-color: #12CB77;
    border: 1px solid #0c9f68;
    display: inline-block;
    width: 100px;
    height: 26px;
    outline: 0;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
}

.header-img {
    background: url(./headBg.gif) no-repeat center center;
    background-size: 100%;
    height: 120px;
    width: 600px;
    margin: 30px auto;
}

.signInButton {
    background-color: #4ca0ff;
    border: 1px solid #0096ff;
    margin: 0 auto;
    display: block;
    width: 200px;
    height: 40px;
    outline: 0;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    border-radius: 4px;
}

.signInButton:hover {
    background-color: #75aaf9;
}

.loader {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, cyan, #fff);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
}

.wave:nth-child(2) {
    animation-delay: 0.1s;
}

.wave:nth-child(3) {
    animation-delay: 0.2s;
}

.wave:nth-child(4) {
    animation-delay: 0.3s;
}

.wave:nth-child(5) {
    animation-delay: 0.4s;
}

.wave:nth-child(6) {
    animation-delay: 0.5s;
}

.wave:nth-child(7) {
    animation-delay: 0.6s;
}

.wave:nth-child(8) {
    animation-delay: 0.7s;
}

.wave:nth-child(9) {
    animation-delay: 0.8s;
}

.wave:nth-child(10) {
    animation-delay: 0.9s;
}

@keyframes wave {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

.searchWrapper::after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
}