.tooltip-container {
  position: absolute;
  display: inline-block;
}

.pinVerifyArea .tooltips-icon,
.subToolsetWrapper .tooltips-icon {
  display: inline-block;
  color: #828282;
  cursor: pointer;
  font-size: 18px;
  height: 16px;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
  transition: 0.25s all ease;
  transition-delay: 0s;
}

.tooltip-container:hover .two-line {
  top: -70px;
}

.tooltip-container:hover .four-line {
  top: -104px;
}

.tooltip-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background: #fff;
  border: 1px solid #828282;
  border-radius: 8px;
  padding: 6px;
  margin: 16px;
  font-size: 13px;
  transition: 0.25s all ease;
  transition-delay: 1s;
  z-index: 2;
}

.normal {
  width: 300px;
}

.wide {
  width: 460px;
}

.two-line {
  left: -32px;
  top: -70px;
}

.three-line {
  left: -32px;
  top: -87px;
}

.four-line {
  left: -32px;
  top: -104px;
}

.five-line {
  left: -32px;
  top: -121px;
}

.six-line {
  left: -32px;
  top: -138px;
}

.seven-line {
  left: -32px;
  top: -155px;
}

.eight-line {
  left: -32px;
  top: -172px;
}

.nine-line {
  left: -32px;
  top: -189px;
}


.tooltip-content .align-right {
  text-align: right;
}

.tooltip-content .align-right a {
  color: red;
  text-decoration: none;
}

.tooltip-content .align-right a:hover {
  text-decoration: underline;
}

.tooltip-content:after {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
  top: 100%;
  left: 15px;
  border-color: #828282 transparent transparent transparent;
}