.inputArea::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.inputLeft {
  position: relative;
}

.searchArea {
  width: 52%;
  float: left;
}

@media screen and (min-width: 950px) and (max-width: 1850px) {
  .searchArea {
    width: 100%;
    float: none;
  }
}

@media screen and (min-width: 500px) and (max-width: 949px) {
  .searchArea {
    width: 100%;
    float: none;
  }
}