body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

div,
p,
h3,
h5,
h4,
ul,
li {
  margin: 0;
  padding: 0;
}

#App {
  text-align: center;
}

#oneview {
  text-align: center;
  border: 1px solid white;
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
}

#ccp {
  display: none;
}

.left{
  float: left;
  width: 45vw;
  height: 82vh;
  border-right: 2px solid #12CB77;
}

.right{
  float: left;
  width: 53vw;
  height: 82vh;
}

@media screen and (min-width: 500px) and (max-width: 949px) {
  .left{
    float: none;
    width: 100%;
    height: auto;
    border-bottom: 2px solid #12CB77;
  }
  
  .right {
      float: none;
      width: 100%;
      height: auto;
  }
}