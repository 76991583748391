.toolset {
    position: relative;
}

.toolsetWrapper {
    margin-left: 40px;
    margin-top: 20px;
}

.toolsetTitle,
.toolsetSubTitle {
    border: 1px solid #818181;
    border-radius: 15px;
    height: 30px;
    width: 250px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    margin: 5px 0;
    color: #818181;
    background-color: white;
    font-size: 14px;
}

.toolsetTitle {
    padding: 0;
    height: 34px;
}

.toolsetTitle:hover,
.toolsetSubTitle:hover,
.selected {
    border-width: 2px;
    color: black;
    border-color: black;
}

.subToolsetWrapper {
    width: 100%;
    padding: 2px 0;
}

.toolsetSubTitle {
    margin-left: 30px;
    display: inline-block;
    vertical-align: top;
}

.subToolset {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}

.active {
    max-height: 800px;
    opacity: 1;
    overflow: hidden;
    transition: opacity 0.5s ease-out, max-height 1s ease-out;
}

.toolsetContentWrapper {
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;
    margin-top: 10px;
}

.toolsetContentWrapper .active {
    display: inline-block;
}

.toolsetContentWrapper .greyPill {
    background-color:#818181;
}

.toolsetContentWrapper .hidden {
    display: none;
}

.rgwBandWidth {
    display: inline-block;
    margin-left: 10px;
}

.rgwBandWidthStream {
    margin-left: 30px;
}

.matchCube,
.green {
    width: 40px;
    height: 14px;
    display: inline-block;
    background-color: #12CB77;
    border-radius: 7px;
    margin-right: 6px;
    margin-left: 30px;
}

.notMatchCube,
.red {
    width: 40px;
    height: 14px;
    display: inline-block;
    background-color: red;
    border-radius: 7px;
    margin-right: 6px;
    margin-left: 30px;
}

.amber {
    width: 40px;
    height: 14px;
    display: inline-block;
    background-color: #FFC000;
    border-radius: 7px;
    margin-right: 6px;
    margin-left: 30px;
}

.grey{
    width: 40px;
    height: 14px;
    display: inline-block;
    background-color: #ababab;
    border-radius: 7px;
    margin-right: 6px;
    margin-left: 30px; 
}

.subToolset .redLight,
.subToolset .amberLight,
.subToolset .greenLight {
    width: 40px;
    height: 14px;
    display: inline-block;
    border-radius: 7px;
    margin-right: 6px;
    margin-left: 30px;
}

.subToolset .redLight {
    background-color: red;
}

.subToolset .amberLight {
    background-color: #FFC000;
}

.subToolset .greenLight {
    background-color: #12CB77;
}

.toolsetContentWrapper i {
    font-style: normal;
}

.LFC {
    position: absolute;
    left: 320px;
    top: 8px;
    font-weight: bold;
}

.LFC .serviceName {
    font-style: normal;
    display: inline-block;
    color: gray;
    margin-right: 10px;
}

.LFC .colon {
    font-style: normal;
    color: gray;
    width: 16px;
    display: inline-block;
}

.LFC .serviceValue {
    font-style: normal;
    display: inline-block;
    color: black;
}

@media screen and (min-width: 950px) and (max-width: 1850px) {

    .toolsetTitle,
    .toolsetSubTitle {
        width: 220px;
    }
}

@media screen and (min-width: 500px) and (max-width: 595px) {

    .toolsetTitle,
    .toolsetSubTitle {
        width: 220px;
    }
}