.pinVerifyArea {
  float: left;
  margin-top: 12px;
  margin-left: 20px;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  position: relative;
}

.pinDropdown .faqDropdown {
  width: 250px;
  margin-bottom: 10px;
}

.pinInput {
  height: 34px !important;
  width: 100px !important;
  margin-left: 0 !important;
  padding: 0 10px;
  font-family: sans-serif;
  font-size: 16px;
  color: black;
  background-color: white;
  border: 1px solid grey;
  border-radius: 20px !important;
  box-shadow: inset 1px 3px 4px 0 rgba(0, 0, 0, 0.1);
  outline: none;
}

.pinInputDisable {
  background-color: #eeeeee;
}

.pinVerifyBtn {
  cursor: pointer;
  height: 34px;
  font-size: 14px;
  padding: 4px 20px 4px 10px;
  line-height: 24px;
  background: url(./green_chervon.png) no-repeat right;
  border: none;
  font-weight: bold;
  display: inline-block;
  margin-left: 26px;
  background-size: 15px;
}

.pinVerifyBtnDisable {
  color: #7f7f7f;
  background: url(./grey_chervon.png) no-repeat right;
  background-size: 15px;
}

.pinVerifyArea .tooltip-container {
  margin-top: 6px;
  margin-left: 6px;
}

.pinNotice {
  position: absolute;
  top: 100px;
  left: 8px;
  font-size: 14px;
}

.notice-red {
  color: red;
}

.notice-red-bold{
  color: red;
}

.notice-green {
  color: #12cb77;
}

.red-bold {
  color: red !important;
  font-weight: bold;
}

.green-bold {
  color: #12cb77 !important;
  font-weight: bold;
}

@media screen and (min-width: 950px) and (max-width: 1085px) {
  .pinVerifyArea {
    float: none;
  }
}

@media screen and (min-width: 500px) and (max-width: 949px) {
  .pinVerifyArea {
    float: none;
  }
}