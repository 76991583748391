.rightWrapper {
  position: relative;
}

.scrollbar {
  width: 100%;
  height: 80vh !important;
}

.copyWrapper {
  height: 30px;
}

.copyBtn {
  background: none;
  color: #666666;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 24px;
  display: block;
  position: relative;
  left: 10px;
  top: 10px;
}

.copyNotice {
  position: relative;
  left: 40px;
  top: -12px;
}

.urgentMsg {
  position: absolute;
  left: 14%;
  top: 6px;
  color: #e60000;
  font-weight: bold;
  font-size: 16px;
  padding: 4px 0;
  width: 80%;
  margin: 0 auto;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden;
}

.urgentMsg span {
  display: inline-block;
  padding-left: 100%;
  animation: urgentInfoMarquee 26s linear infinite;
  -webkit-animation: urgentInfoMarquee 26s linear infinite;
}

.urgentMsg span a {
  text-decoration: underline;
}

.urgentInfoMarquee span:hover {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

@keyframes urgentInfoMarquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes urgentInfoMarquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

.accountInfo {
  padding-top: 10px;
  font-size: 16px;
  margin-left: 20px;
}

.accountInfo::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.accountInfo .infoWrapper {
  color: black;
}

.accountInfo .infoValue {
  font-style: normal;
  display: inline-block;
  word-break: break-word;
}

.accountInfo .column1 {
  float: left;
  width: 350px;
  border-right: 2px solid black;
}

.accountInfo .column2 {
  float: left;
  width: 500px;
  margin-left: 40px;
}


.accountInfo .column1 .infoTitle {
  width: 140px;
}

.accountInfo .column1 .infoValue {
  width: 150px;
  padding-left: 4px;
}


.accountInfo .column2 .infoValue {
  width: 180px;
  padding-left: 4px;
}

.accountInfo .infoTitle {
  font-style: normal;
  width: 150px;
  display: block;
  float: left;
  color: grey;
}

.accountInfo .column2 .infoTitle {
  width: 260px;
}

.accountInfo .light {
  width: 22px;
  height: 12px;
  border-radius: 6px;
  display: block;
  float: left;
  margin-top: 5px;
}

.accountInfo .greenLight {
  background-color: #12CB77;
}

.accountInfo .redLight {
  background-color: red;
}

.accountInfo .amberLight {
  background-color: #FFC000;
}

.accountInfo .colon {
  font-style: normal;
  width: 15px;
  display: block;
  float: left;
}

.service::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.service .serviceLeft {
  color: gray;
  float: left;
  border-right: 2px solid #3a3a3a;
  padding-right: 25px;
}

.service .serviceRight {
  float: left;
  padding-left: 30px;
}

.serviceLeft .serviceValue {
  font-style: normal;
  width: 120px;
  display: inline-block;
  margin-right: 20px;
  color: black;
}

.serviceLeft .redBold {
  color: red;
  font-weight: bold;
}

.serviceLeft .red {
  color: red;
}

.serviceLeft .serviceName{
  width: 140px;
  font-style: normal;
  display: inline-block;
  color: gray;
  text-align: right;
  margin-right: 20px;
}

.serviceRight .serviceName,
.lineTestONT .serviceName {
  font-style: normal;
  width: 120px;
  display: inline-block;
  color: gray;
  text-align: right;
  margin-right: 20px;
}

.lineTestONT .serviceName {
  width: 140px;
}

.lan {
  margin-top: 30px;
}

.service,
.outages,
.modemAuthWrapper,
.loginWrapper,
.lan,
.lineTestONTWrapper,
.internetUsage {
  font-size: 18px;
  margin: 10px 20px 20px;
}


.lan .lanName {
  font-style: normal;
  color: gray;
  width: 180px;
  display: inline-block;
}

.service .colon,
.lan .colon,
.lineTestONT .colon {
  font-style: normal;
  color: gray;
  width: 26px;
  display: inline-block;
}

.service .intactGroup {
  display: inline-block;
  vertical-align: top;
}

.service .intact {
  font-style: normal;
  width: 80px;
  display: inline-block;
}

.service .activeStatus {
  font-style: normal;
  width: 80px;
  display: inline-block;
}

.service .intactNotice {
  display: block;
  font-size: 14px;
}

.service .learnMore {
  margin-left: 10px;
  display: inline-block;
}

.lan .lanValue {
  font-style: normal;
  width: 150px;
  display: inline-block;
}

.lan .columnTitle .lanValue {
  text-align: center;
}

.redText {
  color: red;
}

.lan .active {
  display: inline-block;
}

.lan .hidden {
  display: none;
}

.lanValue .matchCube {
  width: 40px;
  height: 12px;
  display: inline-block;
  background-color: #12CB77;
  border-radius: 6px;
  margin-right: 6px;
  margin-left: 0;
}

.lanValue .notMatchCube {
  width: 40px;
  height: 12px;
  display: inline-block;
  background-color: red;
  border-radius: 6px;
  margin-right: 6px;
  margin-left: 0;
}

.modemAuthWrapper {
  margin: 20px;
}

.modemAuth {
  margin: 10px 30px;
}

.modemAuth i {
  font-style: normal;
}

.modemAuth .modemLight {
  width: 40px;
  height: 12px;
  display: inline-block;
  border-radius: 6px;
  margin-right: 10px;
}

.modemAuth .modemGreen {
  background-color: #12CB77;
}

.modemAuth .modemRed {
  background-color: red;
}

.modemAuth .adviseRestart {
  color: red;
  display: block;
  margin-left: 50px;
}

.loginHistory {
  padding-top: 10px;
}

.loginIcons::after,
.loginTitles::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.loginIcon {
  font-size: 24px;
}

.onlineIcon {
  margin-left: 80px;
}

.offlineIcon {
  color: #bbb;
  margin-left: 130px;
}

.ipIcon {
  float: left;
  display: block;
  width: 40px;
  height: 40px;
  background: url(./ip-address.png) no-repeat;
  background-size: cover;
  margin-left: 110px;
}

.questionIcon {
  margin-left: 150px;
  font-size: 38px;
}

.onlineTitle {
  float: left;
  font-size: 14px;
  margin-top: -18px;
  margin-left: 74px;
  display: block;
  width: 70px;
  font-style: normal;
  color: #12CB77;
  font-weight: bold;
}

.offlineTitle {
  float: left;
  font-size: 14px;
  margin-top: -18px;
  margin-left: 96px;
  display: block;
  width: 70px;
  font-style: normal;
  color: red;
  font-weight: bold;
}

.loginLine i {
  font-style: normal;
  font-size: 14px;
  display: inline-block;
}

.lineColumn {
  text-align: center;
}

.connectionTime {
  width: 140px;
  margin-left: 20px;
  background-color: #c6e0b4;
}

.disconnectTime {
  width: 140px;
  margin-left: 30px;
  background-color: #fdcfcf;
}

.ipAddress {
  width: 110px;
  margin-left: 30px;
  background-color: #d9d9d9;
}

.dropReason {
  width: 200px;
  margin-left: 30px;
  background-color: #d9d9d9;
}

.internetUsage .titleArea::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.internetUsage .titleArea .month,
.internetUsage .titleArea .data {
  float: left;
}

.internetUsage .titleArea .month {
  margin-left: 30%;
  margin-right: 16px;
}

.internetUsage table {
  text-align: center;
  font-size: 16px;
}

.internetUsage .rowName {
  text-align: right;
  font-size: 15px;
}

.internetUsage .days {
  background-color: #eeeeee;
  width: 52px;
}

.internetUsage .inbound {
  background-color: #a9d18e;
  width: 52px;
}

.internetUsage .outbound {
  background-color: #e2f0d9;
  width: 52px;
}

.lineTestONTWrapper .light {
  width: 22px;
  height: 12px;
  border-radius: 6px;
  display: inline-block;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.lineTestONTWrapper .amberLight {
  background-color: #FFC000;
}

.outages .outagesContent {
  margin-top: 10px;
}

.outages .outageNotice {
  width: 100%;
}

.outages .showHistory,
.outages .viewAll,
.outages .eventDetail {
  padding-left: 10px;
  margin-bottom: 10px;
}

.outages .viewAll a {
  padding-right: 4px;
  color: #0070c0;
}

.outages .showHistory {
  color: #0070c0;
  text-decoration: underline;
  cursor: pointer;
}

.outages .chevronDown {
  margin-right: 4px;
}

.outages table th {
  padding: 0 10px;
}

.outages table td {
  text-align: center;
}

@media screen and (min-width: 950px) and (max-width: 1850px) {
  .lan .lanName {
    width: 20%;
  }

  .lan .lanValue {
    width: 30%;
  }

  .lan .lanValue .active {
    width: 32%;
  }
}

@media screen and (min-width: 500px) and (max-width: 595px) {
  .lan .lanName {
    width: 20%;
  }

  .lan .lanValue {
    width: 30%;
  }

  .lan .lanValue .active {
    width: 32%;
  }
}