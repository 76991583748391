.addressCheckerWrap {
  position: relative;
  width: 20vw;
  margin-left: 50px;
}

.inputWrapper {
  width: 100%;
  height: 50px;
}

.searchInput {
  position: absolute;
  height: 34px;
  padding: 0 40px;
  font-family: sans-serif;
  font-size: 16px;
  color: black;
  background-color: white;
  border: 1px solid grey;
  border-radius: 20px;
  box-shadow: inset 1px 3px 4px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  width: 300px;
}

.searchIcon {
  position: absolute;
  width: 32px;
  height: 40px;
  padding: 0;
  left: 10px;
  background: url(./svg/search.svg) no-repeat 100%;
}

.searchSpinner {
  position: absolute;
  width: 28px;
  height: 40px;
  padding: 0;
  left: 346px;
  top: 20px;
}

.clearInput {
  position: absolute;
  width: 20px;
  height: 30px;
  padding: 0;
  left: 348px;
  top: 4px;
  cursor: pointer;
  border: none;
  background: url(./svg/close.svg) no-repeat 100%;
}

.addressListWrap {
  position: absolute;
  padding: 10px;
  width: 500px;
  left: 0;
  top: 30px;
}

.addressList {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 40px;
  left: 10px;
  height: 400px;
  width: 450px;
  overflow-x: hidden;
  background-color: white;
  z-index: 2;
}

;

.listItem {
  margin: 1px;
}

.listItemButton {
  display: block;
  width: 100%;
  padding: 2px;
  font-family: sans-serif;
  font-size: 14px;
  color: black;
  text-align: left;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  outline: none;
  appearance: none;
  transition: white 0.2s linear;
}

.listItemButton:hover {
  background-color: black;
  color: white;
}

.errorMessage {
  position: relative;
  font-size: 16px;
  left: 50px;
  color: #12CB77;
  width: 260px;
}

.disableAddress {
  background-color: #eeeeee;
}

@media screen and (min-width: 500px) and (max-width: 1850px) {
  .addressCheckerWrap {
    width: 48vw;
    margin-left: 36px;
  }

  .errorMessage {
    top: 0;
  }
}

@media screen and (min-width: 500px) and (max-width: 949px) {
  .addressCheckerWrap {
    width: 80vw;
    margin-left: 36px;
  }

  .errorMessage {
    top: 0;
  }
}