.accountSearchWrap {
  padding: 10px;
  width: 23vw;
  height: auto;
  margin-top: 12px;
  position: relative;
}

.clearBtnWrap {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 34px;
  display: block;
  float: left;
}

.clearBtn {
  color: #12CB77;
  cursor: pointer;
}

.accountSearch {
  height: 34px;
  padding: 0 10px;
  font-family: sans-serif;
  font-size: 16px;
  color: black;
  background-color: white;
  border: 1px solid grey;
  border-radius: 20px;
  box-shadow: inset 1px 3px 4px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  width: 10vw;
}

.searchBtn {
  cursor: pointer;
  height: 34px;
  font-size: 14px;
  padding: 4px 20px 4px 10px;
  line-height: 24px;
  background: url(../PIN/green_chervon.png) no-repeat right;
  border: none;
  font-weight: bold;
  background-size: 15px;
}

.searchNotice {
  margin-left: 50px;
  margin-top: 2px;
  color: #12CB77;
}

.cartridgeList {
  width: max-content;
  margin-top: 20px;
}

.cartridge {
  margin-left: 40px;
  margin-top: 4px;
  padding: 2px 10px;
  width: fit-content;
  cursor: pointer;
  float: left;
}

.cartridgeItem::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.cartridgeActive{
  background-color: #e2e2e2;
}

.chorusAddress{
  float: left;
  margin-left: 20px;
  margin-top: 4px;
  padding: 2px 10px;
  color: red;
}

.tipsIcon {
  display: inline-block;
  margin-left: 10px;
  color: #828282;
  cursor: pointer;
  font-size: 18px;
  height: 16px;
}

.tips {
  position: absolute;
  width: 300px;
  font-size: 12px;
  border: 1px solid;
  border-radius: 14px;
  padding: 10px;
  top: -40px;
  left: 310px;
  background-color: white;
  z-index: 1;
}

.shown {
  display: block;
}

.hidden {
  display: none;
}

.disableAccount {
  background-color: #eeeeee;
}

.support{
  color: red;
  font-size: 13px;
  width: 110%;
  margin-top: 16px;
}

.searchTable{
 margin-left: 40px; 
 cursor: pointer;
}

.searchTableTitle{
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
}

.searchTable table, .searchTable td{
  border: 1px solid;
  padding: 2px 4px;
}

.searchTable table{
  border-collapse: collapse;
}

.searchTable .active{
  background-color: #a9d18e;
}

.searchTable .vul_cus{
  color: red;
  font-weight: bold;
  border:black 1px solid
}

@media screen and (min-width: 950px) and (max-width: 1850px) {
  .accountSearchWrap {
    width: 43vw;
  }

  .accountSearch {
    width: 20vw;
  }
}

@media screen and (min-width: 950px) and (max-width: 1085px) {
  .support{
    width: 100%;
  }
}

@media screen and (min-width: 500px) and (max-width: 949px) {
  .inputLeft {
    float: none;
  }

  .accountSearchWrap {
    width: 100%;
  }

  .accountSearch {
    width: 30vw;
  }
}